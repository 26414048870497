/* billing-address */

.billing-address {
    margin-bottom: 43px;

    &__ordered-before {
        margin-bottom: 16px;
    }
}

.shipping-info {
    margin-bottom: 15px;
}