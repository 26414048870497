/* .checkbox */

.checkbox {
    
    &__input {
        appearance: none;
        position: absolute;
        border: none;
    }
    
    &__label {
        position: relative;
        font-size: 16px;
        color: #444;
        padding-left: 32px;
        display: inline-block;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 2px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        &:after {
            content: '\e911';
            font-family: 'icons' !important;
            position: absolute;
            display: none;
            font-size: 12px;
            color: #fff;
            top: 0;
            left: 4px;
            z-index: 2;
        }

        .checkbox__input:checked + &:before {
            background-color: @c_brand;
            border: 1px solid @c_brand;
        }
        
        .checkbox__input:checked + &:after {
            display: block;
        }
    }
}