/* .shopping-footer */

.shopping-footer {
    border-top: 1px solid #D2D4D9;
    padding-top: 25px;

    @media @md {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    @media @xl {
        padding-top: 30px;
        align-items: flex-end;
        margin-bottom: 30px;
    }

    &__total {
        text-align: center;
        font-family: @ff_second;
        font-weight: 700;
        font-size: 20px;
        font-variant-numeric: lining-nums;
        letter-spacing: -1px;
        margin-bottom: 23px;

        @media @xl {
            margin-bottom: 0;
            margin-right: 34px;
        }
    }

    &__btn {
        text-align: center;
    }

    &__submit {
        margin-bottom: 33px;

        @media @md {
            margin-bottom: 0;
        }

        @media @xl {
            display: flex;
            align-items: center;
            padding-right: 3px;
            padding-bottom: 4px;
        }
    }
    &__ssl {
        text-align: center;

        @media @md {
            margin-right: 15px;
            text-align: left;
            max-width: 480px;
        }
    }
    &__ssl-list {
        margin-bottom: 7px;

        @media @md {
            text-align: left;
            margin-bottom: 20px;
        }

        @media @xl {
            margin-bottom: 9px;
        }
    }
    &__ssl-list img {
        height: 20px;

        @media @xl {
            height: 29px;
        }
    }
    &__ssl-list img:first-child {
        margin-right: 25px;

        @media @xl {
            margin-right: 37px;
        }
    }
    &__ssl-desc {
        font-size: 12px;
        line-height: 16px;
        color: #5C6471;
        padding: 0 5px;

        @media @xl {
            padding: 0;
        }
    }

    .btn {
        width: 100%;
        max-width: 328px;
        text-align: center;
        padding: 11px 19px;

        @media @md {
            width: auto;
            white-space: nowrap;
            padding: 11px 17px;
        }
    }
}