/*.search*/

@media @lg {
  /*.search @lg*/
}

.search {
  display: flex;
  padding: 0 20px;

  @media @lg {
    padding: 0;
    margin-bottom: 10px;
  }

  &__field {
    flex-grow: 1;
    border: 1px solid #D2D4D9;
    border-radius: 6px;
    margin-right: 13px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin-right: 6px;
  }
  &__input {
    height: 48px;
    padding: 0 14px;
    &::-webkit-input-placeholder {color:#BAC0C9;}
    &::-moz-placeholder          {color:#BAC0C9;}/* Firefox 19+ */
    &:-moz-placeholder           {color:#BAC0C9;}/* Firefox 18- */
    &:-ms-input-placeholder      {color:#BAC0C9;}
    outline: none;
    border: none;
    font-family: @ff_base;
    flex-grow: 1;
    background-color: transparent;

    @media @lg {
      height: 46px;
    }
  }
  &__submit {
    background-color: transparent;
    border: none;
    font-size: 20px;
    padding: 3px 12px 0;
    cursor: pointer;

    @media @lg {
      font-size: 22px;
    }
  }
  &__reset {
    border: none;
    font-size: 11px;
    background-color: transparent;
    cursor: pointer;
  }
}