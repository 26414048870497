/* .tabs */

@media @lg { /* .tabs @lg */ }

.tabs {
    display: block;

    &__nav--product {
        display: flex;
        overflow-x: auto;

        //@media @md {
        //    margin-bottom: -6px;
        //}

        body.opened & {
            overflow: hidden;
        }
    }

    &__radio {
        display: none;
    }

    &__label {
        cursor: pointer;
        display: inline-block;
        font-family: @ff_second;

        padding: 8px 16px;
        white-space: nowrap;
        font-weight: 800;
        font-variant-numeric: lining-nums;
        letter-spacing: 1px;
        border-radius: 20px;
        color: #5C6471;

        @media @lg {
            letter-spacing: .8px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
            color: @c_text;
        }

        .tabs__radio:checked + & {
            color: #fff;
            background-color: @c_brand;
        }

        .tabs__radio:checked + &:hover {
            background-color: #6f8d33;
        }

        //.tabs__nav--product & {
        //    @media @md {
        //        padding: 6px 16px;
        //    }
        //}
    }

    &__item {
        display: none;
        padding-top: 16px;
        padding-bottom: 20px;

        @media @lg {
            padding-top: 11px;
        }

        .shopping-page__paiment-right & {
            padding-top: 13px;

            @media @xl {
                padding-top: 16px;
            }
        }
    }

    //&__item-credit-card {
    //    @media @md {
    //        background: url("../img/payment.png") no-repeat;
    //        background-size: contain;
    //        min-height: 379px;
    //        padding-top: 46px;
    //        padding-left: 37px;
    //        padding-right: 176px;
    //        position: relative;
    //        margin-left: -25px;
    //    }
    //
    //    @media @xl {
    //        padding-top: 87px;
    //        padding-left: 67px;
    //        padding-right: 245px;
    //    }
    //}

    &__item-credit-card {
        @media @xl {
            background: url("../img/card.png") no-repeat 0 0;
            position: relative;
            left: -20px;
            margin-right: -20px;
            padding: 87px 244px 30px 68px;
        }
    }

    &__item-credit-card .form__row {
        @media @xl {
            margin-bottom: 24px;
        }
    }

    &__item-credit-card .form__field {
        overflow: visible;
    }

    &__item-credit-card .form__error {
        @media @xl {
            position: absolute;
            top: 54px;
            left: 8px;
        }
    }

    &__item-paypal img {
        width: 150px;
    }
    &__item-paypal-header {
        margin-bottom: 0;
    }
}