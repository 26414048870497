/*.top-phones*/

@media @lg {
  /*.top-phones*/
}

.top-phones {
  padding-left: 24px;

  @media @lg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 2px;
  }

  i {
    color: #EDEEF0;
    font-size: 14px;
    margin-top: -5px;
    margin-right: 5px;

    @media @lg {
      color: #313337;
      display: none;
    }
  }
  &__item:first-child i {
    @media @lg {
      display: block;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @media @lg {
      margin-right: 18px;
    }
  }
  &__item:last-child {
    @media @lg {
      margin-right: 0;
    }
  }
  &__item-label {
    color: #7C828B;
    font-size: 12px;
    margin-right: 2px;
  }
}