/* .banner */

@media @xs {/* .banner @xs */}
//@media @md {/* .banner @md */}
//@media @lg {/* .banner @lg */}

.banner {
    border-radius: 8px;
    margin-bottom: 8px;
    background: #191B1D url('../img/android.png') no-repeat 100% 100%;
    background-size: 100px auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 90px 16px 24px;

    @media @xs {
        padding-right: 180px;
        background-size: 148px auto;
    }

    @media @lg {
        padding-right: 90px;
        background-size: 100px auto;
    }

    @media @xl {
        padding-right: 180px;
        background-size: 148px auto;
    }

    &__title {
        color: @c_brand;
        font-size: 42px;
        line-height: 48px;
        font-weight: bold;
        font-family: @ff_second;
        letter-spacing: -1px;
        margin-bottom: -7px;
        white-space: nowrap;
        font-variant-numeric: lining-nums;
    }

    &__desc {
        color: #f5f5f5;
        letter-spacing: -1.1px;
    }

    &__btn {
        margin-top: auto;
        margin-left: -12px;
    }

    &--lg {
        @media @lg {
            height: 120px;
            border-radius: 12px;
            position: relative;
            padding: 14px 44% 22px 72px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background-position: 68% 6px;
            background-size: 171px auto;
        }

        @media @xl {
            background-size: 171px auto;
            padding-right: 44%;
        }

        .banner__title {
            @media @lg {
                margin-bottom: 0;
            }
        }

        .banner__desc {
            @media @lg {
                padding-left: 6px;
                letter-spacing: 0;
            }
        }

        .banner__btn {
            @media @lg {
                position: absolute;
                top: 36px;
                right: 72px;
            }
        }

        .btn {
            @media @lg {
                padding: 14px 18px;
            }
        }
    }
}