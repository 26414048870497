/* .card */

@media @lg {/* .card @lg */}

.card {
    font-size: 14px;
    font-family: inherit;
    line-height: 18px;
    color: #5C6471;
    background-color: #fff;
    border-radius: 8px;
    padding: 25px 24px;
    margin-bottom: 7px;
    display: block;
    text-decoration: none;

    @media @lg {
        margin-bottom: 15px;
    }
    
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__body {
        word-wrap: break-word;
    }
    
    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: @c_text;
        margin-bottom: 9px;
        letter-spacing: -1px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-family: @ff_second;
        display: block;
    }

    &__img {
        width: 60px;
        height: 49px;
        min-width: 60px;
    }

    &__dosage {
        font-size: 12px;
        line-height: 14px;
        color: @c_text;
        line-break: anywhere;

        span {
            display: block;
        }
    }

    &__desc {
        margin: 0;
        line-height: 20px;
    }

    &__body {
        margin-bottom: 24px;
        display: block;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__price {
        font-size: 24px;
        font-family: @ff_second;
        color: @c_text;
        line-height: 24px;
        font-weight: 800;
        letter-spacing: -1px;
        font-variant-numeric: lining-nums;
    }
}