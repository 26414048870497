/*.cart*/

@media @lg {
  /*.cart @lg*/
}

.cart {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: space-between;
  padding: 12px 7px 9px;
  font-family: @ff_base;

  @media @lg {
    justify-content: flex-end;
  }

  &__icon {
    font-size: 26px;
    color: @c_brand;
    padding-left: 19px;
    padding-top: 3px;
    margin-right: 14px;
  }
  &__body {
    flex-grow: 1;

    @media @lg {
      color: #F5F5F5;
      flex-grow: inherit;
      align-items: center;
      display: flex;
      margin-right: 26px;
    }
  }
  &__title {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1px;

    @media @lg {
      display: inline-block;
      font-size: 14px;
      font-family: @ff_third;
      font-weight: 500;
      margin-right: 4px;
    }
  }
  &__total {
    font-size: 14px;
  }
  &__amount {
    @media @lg {
      padding-left: 10px;
      color: #BAC0C9;
    }
  }
  &__btn {
    @media @lg {
      padding-right: 2px;
    }
  }
  &__btn .btn {
    @media @lg {
      padding: 6px 10px;
    }
  }
}