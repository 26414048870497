/* .blister */

@media @lg {/* .blister @lg */}

.blister {
    background-color: #fff;
    border-radius: 8px;
    padding: 23px 10px 23px 24px;
    margin-bottom: 46px;
    
    @media @lg {
        margin-top: 8px;
        padding: 23px 24px;
        margin-bottom: 22px;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 17px;

        @media @lg {
            margin-bottom: 25px;
        }
    }

    &__item {
        height: 81px;
        width: auto;
        margin-right: 8px;

        @media @lg {
            height: 161px;
        }
    }

    &__pill {
        height: 56px;
    }

    &__title {
        font-size: 20px;
        font-family: @ff_second;
        margin-top: 0;
        margin-bottom: 8px;
        letter-spacing: -1px;

        @media @lg {
            margin-bottom: 7px;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        display: inline-block;
        font-weight: normal;
        line-height: 20px;
        margin-right: 3px;
    }
    &__list-item:before {
        content: "/";
        color: #BAC0C9;
        display: inline-block;
        margin-right: 4px;
    }
    &__list-item:first-child:before {
        display: none;
    }
    &__list-link {
        text-decoration: none;
    }
    &__list-link:hover {
        text-decoration: underline;
    }
    &__ingredients {
        margin-bottom: 17px;

        @media @lg {
            margin-bottom: 25px;
        }
    }
    &__manufacturer {
        margin-bottom: 15px;

        @media @lg {
            margin-bottom: 25px;
        }
    }
}

.blister--main {
    .blister__header {
        @media @md {
            float: left;
            margin-right: 10px;
        }
    }
}