/*.btn*/

.btn {
  font-family: @ff_base;
  color: #FAFAFA;
  font-size: 16px;
  font-weight: 700;
  background-color: @c_brand;
  border: 1px solid @c_brand;
  border-radius: 8px;
  padding: 6px 11px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: darken(@c_brand, 15%);
    border-color: darken(@c_brand, 15%);
  }

  &--lg {
    padding: 14px 19px;
  }

  &--light {
    background-color: #FAFAFA;
    border-color: #FAFAFA;
    color: @c_bgDark;
    
    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
  
  &--dark {
    background-color: @c_bgDark;
    color: #FAFAFA;
    border-color: #fff;

    &:hover {
      background-color: lighten(@c_bgDark, 5%);
      border-color: #fff;
    }
  }

  &--wide {
    width: 100%;
  }
}