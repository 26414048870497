/*.crumbs*/

@media @lg {
    /*.crumbs @lg*/
}

.crumbs {
    background-color: #313337;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 10px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 38px;

    @media @lg {
        background-color: transparent;
        margin: -13px -8px 5px;
        padding: 0 8px;
    }

    &__item {
        color: @c_brand;
        text-decoration: none;
        font-family: @ff_third;
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;

        &:hover {
            color: #EDEEF0;
        }

        @media @lg {
            &:hover {
                color: @c_brand;
            }
        }
    }

    &__item--active {
        display: none;
        color: #BAC0C9;

        &:hover {
            color: #BAC0C9;
        }

        @media @lg {
            display: block;

            &:hover {
                color: #BAC0C9;
            }
        }
    }

    &__arrow {
        color: #EDEEF0;
        transform: rotate(90deg);
        font-size: 5px;
        margin-right: 9px;
        margin-left: 4px;
        display: flex;
        align-items: center;

        @media @lg {
            color: #313337;
            transform: rotate(-90deg);
            font-size: 5px;
        }

        &:first-child {
            @media @lg {
                display: none;
            }
        }
    }

    &__arrow--active {
        display: none;

        @media @lg {
            display: block;
        }
    }
}