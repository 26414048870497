/* .qty */

.qty {
    border: 1px solid #D2D4D9;
    padding: 0;
    background-color: #fff;
    border-radius: 6px;
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &__btn {
        border-width: 0;
        width: 39px;
        border-color: #D2D4D9 !important;
        background-color: #EDEEF0;
        cursor: pointer;

        &:hover {
            background-color: #E3E3E3;
        }
    }

    &__btn--decrement {
        font-size: 2px;
        border-right: 1px solid #D2D4D9;
    }

    &__btn--increment {
        font-size: 10px;
        border-left: 1px solid #D2D4D9;
    }

    &__input {
        text-align: center;
        padding: 0 !important;
        width: 56px;
        border: none !important;
        background-color: transparent !important;
        outline: none;
    }
}