/*.timer*/

@media @xl {
    /*.timer @xl*/
}

.timer {
    margin-bottom: 35px;

    @media @xl {
        margin-bottom: 50px;
    }

    &.timeTo-black div {
        border-color: #191B1D;
        border-radius: 2px;
    }

    figcaption {
        font-size: 11px;
        text-transform: capitalize;
        padding-top: 2px;
    }

    span {
        color: #fafafa;
    }

    figure > div {
        width: 25px !important;
        height: 41px !important;
    }

    figure li {
        font-variant-numeric: lining-nums;
    }
}