/*.menu*/

@media @lg {
  /*.menu @lg*/
}

.menu {
  margin-bottom: 17px;

  @media @lg {
    border-bottom: 1px solid #D2D4D9;
    padding-left: 11px;
    padding-bottom: 10px;
  }

  &__list {
    list-style: none !important;
    margin: 0;
    padding: 0;
    padding-top: 20px;

    @media @lg {
      padding-top: 0;
    }
  }
  &__item {
    @media @lg {
      display: inline-block;
    }
  }
  &__link {
    display: inline-block;
    text-decoration: none;
    color: #F5F5F5;
    font-family: @ff_second;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 25px;
    text-transform: capitalize;

    @media @lg {
      color: #0F1011;
      font-weight: 800;
      font-size: 17px;
      padding: 8px 6px;
    }
  }
  &__link:hover {
    text-decoration: underline;
  }
}