/*.footer*/

@media @lg { /* .footer */ }

.footer {
    background-color: @c_bgDark;
    text-align: center;
    padding-top: 19px;
    padding-bottom: 102px;
    margin-top: auto;

    &__nav {
        margin: 0 0 9px;
        padding: 0;
        list-style: none !important;
    }
    &__nav-item {
        display: inline-block;
        margin: 0 6px 7px;
    }
    &__nav-link {
        text-decoration: none;
        font-size: 14px;
        font-family: @ff_second;
        font-weight: 700;
        color: #F5F5F5;
    }
    &__nav-link:hover {
        opacity: .7;
    }
    &__copyright {
        font-size: 14px;
        line-height: 20px;
        color: #7C828B;
    }
    &__social-notes {
        margin-bottom: 15px;

        @media @lg {
            margin-bottom: 17px;
        }
    }
    &__paysystems {
        margin-bottom: 8px;

        @media @lg {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 14px;
        }
    }
    &__paysystems img {
        height: 20px;

        @media @lg {
            height: auto;
        }
    }
    &__paysystems span {
        display: inline-block;
        margin: 0 8px 10px;

        @media @lg {
            margin: 0 10px;
        }
    }
}