/*.slider*/

@media @md {
    /*.slider @md*/
}

@media @xl {
    /*.slider @xl*/
}

.slider {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 24px;

    @media @md {
        margin-left: 0;
        margin-right: 0;
    }

    &__item {
        position: relative;
        height: 428px;
        overflow: hidden;
        display: flex;

        @media @xl {
            height: 340px;
            border-radius: 20px;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: none;
        object-fit: cover;
        object-position: 80% 50%;
        z-index: 1;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        color: #4C4F55;
        font-size: 6px;

        &:after {
            display: none;
        }

        i {
            display: block;
        }

        &:hover {
            box-shadow: 0 0 5px rgba(0, 0, 0, .3);
        }

        @media @xl {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .swiper-button-next {
        transform: translate(-10px, -7px);

        i {
            transform: rotate(-90deg);
        }
    }

    .swiper-button-prev {
        transform: translate(10px, -7px);

        i {
            transform: rotate(90deg);
        }
    }

    &__body {
        position: relative;
        z-index: 3;
        padding: 33px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        max-width: 400px;

        @media @xl {
            margin-left: 105px;
            padding: 16px 16px 46px;
        }
    }

    &__title {
        margin: 0 0 22px 0;
        font-family: @ff_second;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: -1px;
        color: @c_text;

        @media @xl {
            color: #FAFAFA;
            font-size: 42px;
            margin-bottom: 27px;
        }
    }

    &__text {
        color: #F5F5F5;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;

        @media @xl {
            padding-right: 20px;
        }
    }

    &__bg {
        position: absolute;
        top: 90px;
        left: -242px;
        width: 721px;
        height: 721px;
        border-radius: 50%;
        background-color: @c_brand;
        z-index: 2;

        @media @xl {
            height: 468px;
            width: 468px;
            top: -53px;
            left: 48px;
        }
    }
}