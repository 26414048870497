.icon {
    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-image: url("../img/@{spritesheet-image}") !important;
    }

    //     &-example {
    //         &:before {
    //             .sprite(@icon-example);
    //         }
    //     }

    &-bonus-delivery {
        &:before {
            .sprite(@icon-bonus-delivery);
        }
    }

    &-bonus-security {
        &:before {
            .sprite(@icon-bonus-security);
        }
    }

    &-bonus-certify {
        &:before {
            .sprite(@icon-bonus-sertify);
        }
    }

    &-bonus-medicine {
        &:before {
            .sprite(@icon-bonus-medicine);
        }
    }

    &-quote {
        &:before {
            .sprite(@icon-quote);
        }
    }
}

@font-face {
    font-family: 'icons';
    src: url('../fonts/icons/icons.eot?emod3m');
    src: url('../fonts/icons/icons.eot?emod3m#iefix') format('embedded-opentype'),
    url('../fonts/icons/icons.ttf?emod3m') format('truetype'),
    url('../fonts/icons/icons.woff?emod3m') format('woff'),
    url('../fonts/icons/icons.svg?emod3m#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.i-email:before {
    content: "\e912";
}

.i-checkbox:before {
    content: "\e911";
}

.i-lock:before {
    content: "\e910";
}

.i-trash:before {
    content: "\e90d";
}

.i-minus:before {
    content: "\e90e";
}

.i-plus:before {
    content: "\e90f";
}

.i-bonus-free-shipping .path1:before {
    content: "\e907";
    color: rgb(25, 27, 29);
}

.i-bonus-free-shipping .path2:before {
    content: "\e908";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.i-bonus-hot .path1:before {
    content: "\e909";
    color: rgb(252, 13, 99);
}

.i-bonus-hot .path2:before {
    content: "\e90a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.i-bonus-present .path1:before {
    content: "\e90b";
    color: rgb(153, 191, 78);
}

.i-bonus-present .path2:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.i-phone:before {
    content: "\e906";
}

.i-dropdown-arrow:before {
    content: "\e905";
}

.i-mob-search:before {
    content: "\e904";
}

.i-search:before {
    content: "\e903";
}

.i-cart:before {
    content: "\e902";
}

.i-burger:before {
    content: "\e900";
}

.i-close:before {
    content: "\e901";
}