/* .categories */

@media @lg {/* .categories @lg */}

.categories {
  background-color: #fff;
  margin-left: -8px;
  margin-right: -8px;
  padding: 0 8px;

  @media @lg {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none !important;
  }
  &__link {
    display: block;
    text-decoration: none;
    margin-left: -8px;
    margin-right: -8px;
    padding: 6px 32px;
    color: @c_text;
    font-weight: 800;
    font-size: 17px;
    letter-spacing: -0.2px;

    @media @lg {
      padding: 6px 17px;
    }
  }
  &__link:hover {
    background-color: @c_brand;
    color: #fff;
    border-radius: 4px;
  }
  .categories__item:first-child &__link {
    padding-top: 22px;

    @media @lg {
      padding-top: 6px;
    }
  }
  .categories__item:last-child &__link {
    padding-bottom: 22px;

    @media @lg {
      padding-bottom: 6px;
    }
  }
}