/* .bonuses */

@media @lg { /* .bonuses @lg */ }

.bonuses {
    background-color: #fff;
    border-radius: 12px;
    padding: 23px;
    font-size: 16px;
    font-weight: 700;

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        .bonuses--horizontal & {
            @media @lg {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D2D4D9;
        padding: 8px 0;

        &:last-child {
            border-bottom: none;
        }

        .bonuses--horizontal & {
            @media @lg {
                border-bottom: none;
                flex-grow: 1;
                border-right: 1px solid #D2D4D9;
                padding: 8px 17px;
                width: 25%;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    &__label {
        margin-right: 5px;
    }
}