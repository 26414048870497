/*.select*/

@media @lg {
  /*.select @lg*/
}

.select {

  .header & {
    padding: 0 4px;
    position: relative;
  }

  .header & .i-dropdown-arrow {
    color: #BFC7C6;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 6px;
    pointer-events: none;
  }

  .header &__field {
    width: 100%;
    height: 35px;
    border-radius: 6px;
    background-color: @c_bgDark;
    color: #F5F5F5;
    font-family: @ff_base;
    font-size: 14px;
    line-height: 20px;
    padding: 0 24px 0 8px;
    appearance: none;
    outline: none;

    @media @lg {
      min-width: 96px;
    }
  }
}