html {
    height: 100%;
}

.page {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &__title--sm {
        font-size: 28px;
        margin-bottom: 11px;

        @media @lg {
            margin-bottom: 18px;
        }
    }

    &__main {
        margin-bottom: 39px;

        @media @lg {
            margin-bottom: 31px;
        }
    }

    // &__section {
    //     position: relative;
    //     z-index: 0;
    // }
}

.account-page,
.coupon-page,
.subscribe-page {

    input,
    textarea {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 4px;
        border: 1px solid #D1D8E3;
        outline: none;
    }

    input {
        height: 56px;
    }

    textarea {
        height: 192px !important;
    }

    input[type="submit"] {
        padding: 6px 30px !important;
        border-radius: 4px !important;
    }

    input[type="submit"]:hover {
        background-color: #6f8d33 !important;
        border-color: #6f8d33;
        color: #fff !important;
    }

    input[name="vcode"] {
        margin-right: 5px;
    }

    img.vcode {
        margin-right: 5px;
    }

    table,
    thead,
    tbody,
    tfoot,
    tr,
    td {
        display: block;
    }

    table {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        @media @sm {
            display: table;
        }
    }

    thead {
        @media @sm {
            display: table-header-group;
        }
    }

    tbody {
        @media @sm {
            display: table-row-group;
        }
    }

    tfoot {
        @media @sm {
            display: table-footer-group;
        }
    }

    tr {
        @media @sm {
            display: table-row;
        }
    }

    th,
    td {
        @media @sm {
            display: table-cell;
        }
    }

    table input {
        width: 100%;

        @media @sm {
            width: auto;
        }
    }

    table input[name="vcode"] {
        width: auto;
    }

    table button,
    table input[type="submit"] {
        display: inline-block;
        background-color: @c_brand;
        border: 1px solid @c_brand;
        color: #fafafa;
        padding: 6px 11px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        font-family: @ff_base;
        width: auto;

        &:hover {
            background-color: @c_bgBody;
            color: @c_text;
        }
    }

    th,
    td {
        padding: 0;

        @media @sm {
            padding: 5px;
            text-align: left;
        }
    }

    th:first-child,
    td:first-child {
        @media @sm {
            text-align: right;
        }
    }

    tr {
        margin-bottom: 4px;
    }

    tbody {
        order: 0;
    }

    tfoot {
        order: 1;
    }

    tfoot th:first-child {
        display: none;

        @media @sm {
            display: table-cell;
        }
    }

    tfoot th[colspan="2"] {
        display: block;
        text-align: left;

        @media @sm {
            padding-left: 31%;
        }
    }
}

table.simple {
    margin: 15px 0;

    thead th,
    thead td {
        font-family: @ff_second;
        text-align: center;
        font-size: 13px;
        line-height: 16px;
        color: #5c6471;
        font-weight: bold;

        &:first-child {
            text-align: left;
        }
    }

    tbody td,
    tbody th {
        border-top: 1px solid #d2d4d9;
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

.testimonial {
    border-bottom: 1px dotted #BAC0C9;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }

    .test-header {
        text-align: right;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.product-page {
    &__content {
        flex-direction: column-reverse;

        @media @lg {
            flex-direction: row;
        }
    }

    &__subtitle {
        font-family: @ff_second;
        font-weight: 700;
        font-size: 20px;
        color: #5C6471;
        margin-top: -11px;
        margin-bottom: 15px;
        letter-spacing: -1px;
    }

    &__desc {
        margin-bottom: 20px;

        @media @lg {
            margin-bottom: 34px;
        }
    }

    &__desc strong {
        letter-spacing: -0.9px;
    }

    &__desc span {
        display: none;

        @media @lg {
            display: inline;
        }
    }

    &__desc span.opened {
        display: inline;
    }

    &__more {
        display: block;
        text-decoration: none;

        @media @lg {
            display: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__more.opened {
        display: none;
    }

    p {
        margin-top: 0;
        margin-bottom: 24px;
    }

    .bonuses {
        margin-bottom: 40px;

        @media @lg {
            margin-bottom: 21px;
        }
    }

    .banner {
        margin-bottom: 1px;
    }

    .page__title {
        @media @lg {
            margin-top: 21px;
        }
    }

    .col-lg-8 {
        @media @lg {
            padding-left: 36px;
        }
    }

    .tabs {
        @media @lg {
            margin-bottom: -10px;
        }
    }
}

.custom-list {
    margin: 0 0 24px;
    padding-left: 47px;
    list-style: none;

    li {
        margin-bottom: 12px;
        position: relative;
    }

    li:before {
        content: "";
        display: block;
        position: absolute;
        left: -23px;
        top: 8px;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        background-color: @c_brand;
    }
}

.shopping-page {
    &__cart {
        margin-bottom: 20px;

        @media @lg {
            margin-bottom: 0;
        }
    }
    &__paiment-left {
        margin-bottom: 10px;

        @media @xl {
            padding-right: 40px;
        }
    }
    &__paiment-right {
        margin-bottom: -3px;
    }
}