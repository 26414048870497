.container {
    width: 100%;
    max-width: @container;
    //max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
}

.row {
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    flex-wrap: wrap;

    @media @lg {
        margin-left: -16px;
        margin-right: -16px;
    }

    &--8 {
        @media @lg {
            margin-left: -8px;
            margin-right: -8px;
        }
    }
}

img {
    display: inline-block;
    max-width: 100%;
}

.t-center {
    text-align: center;
}


.col {
    padding: 0 8px;
    width: 100%;
}

.col-md-4 {
    @media @md {
        flex-grow: inherit;
        width: 100% / 12 * 4;
    }
}

.col-md-5 {
    @media @md {
        flex-grow: inherit;
        width: 100% / 12 * 5;
    }
}

.col-md-6 {
    @media @md {
        flex-grow: inherit;
        width: 100% / 12 * 6;
    }
}

.col-md-7 {
    @media @md {
        flex-grow: inherit;
        width: 100% / 12 * 7;
    }
}

.col-md-8 {
    @media @md {
        flex-grow: inherit;
        width: 100% / 12 * 8;
    }
}

.col-lg-2 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 2;
    }
}

.col-lg-3 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 3;
    }
}

.col-lg-4 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 4;
    }
}

.col-lg-5 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 5;
    }
}

.col-lg-6 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 6;
    }
}

.col-lg-7 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 7;
    }
}

.col-lg-8 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 8;
    }
}

.col-lg-9 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 9;
    }
}

.col-lg-10 {
    @media @lg {
        flex-grow: inherit;
        width: 100% / 12 * 10;
    }
}

.col-xl-3 {
    @media @xl {
        flex-grow: inherit;
        width: 100% / 12 * 3;
    }
}

.col-xl-4 {
    @media @xl {
        flex-grow: inherit;
        width: 100% / 12 * 4;
    }
}

.col-xl-9 {
    @media @xl {
        flex-grow: inherit;
        width: 100% / 12 * 9;
    }
}

.block-from-md {
    display: none !important;

    @media @md {
        display: block !important;
    }
}

.inline-block-to-lg {
    display: inline-block !important;

    @media @lg {
        display: none !important;
    }
}

.inline-block-from-lg {
    display: none !important;

    @media @lg {
        display: inline-block !important;
    }
}

.block-to-lg {
    display: block !important;

    @media @lg {
        display: none !important;
    }
}

.block-from-lg {
    display: none !important;

    @media @lg {
        display: block !important;
    }
}

.flex-to-lg {
    display: flex !important;

    @media @lg {
        display: none !important;
    }
}

.flex-from-lg {
    display: none !important;

    @media @lg {
        display: flex !important;
    }
}

.mt-21 {
    margin-top: 21px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-17 {
    margin-bottom: 17px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

@media @lg {
    .fz-lg-42 {
        font-size: 42px !important;
    }

    .mb-lg-26 {
        margin-bottom: 26px !important;
    }

    .mt-lg-30 {
        margin-top: 30px !important;
    }
}