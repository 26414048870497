/*.search-by-letters*/

@media @lg {
  /*.search-by-letters @lg*/
}

.search-by-letters {
  @media @lg {
    color: #BAC0C9;
    font-size: 14px;
  }

  &__label {
    @media @lg {
      margin-right: 5px;
    }
  }
  
  &__item {
    @media @lg {
      color: #F5F5F5;
      font-family: @ff_second;
      font-weight: 700;
      text-decoration: none;
      margin-right: 8px;
    }
  }
  &__item:hover {
    @media @lg {
      text-decoration: underline;
    }
  }
}