/* .quote */

@media @lg {/* .quote @lg */}

.quote {
    background-color: @c_brand;
    border-radius: 8px;
    text-align: center;
    position: relative;
    padding: 16px 25px 17px;
    margin-bottom: 2px;
    color: #F5F5F5;

    @media @lg {
        padding: 55px 215px 29px;
        margin-bottom: 9px;
    }

    &:before {
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -17px;
    }

    &__text {
        margin-top: 0;
        margin-bottom: 12px;

        @media @lg {
            font-style: italic;
            margin-bottom: 24px;
        }
    }

    &__author {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        color: #fff;
    }

    .icon {
        display: inline-block;
        margin-bottom: 12px;

        @media @lg {
            margin-bottom: 24px;
        }
    }
}