/* .tbl */

@media @lg {
    /* .tbl @lg */
}

.tbl {
    font-family: @ff_base;

    &__thead-th {
        font-family: @ff_second;
        font-size: 14px;
        line-height: 16px;
        color: #5C6471;
        text-align: center;
        padding: 8px 9px 12px;
        border-bottom: 1px solid #D2D4D9;
    }

    &__thead-th:first-child {
        text-align: left;
    }

    &__tbody-td {
        border-bottom: 1px solid #D2D4D9;
        text-align: left;
        padding: 8px 9px 7px;

        @media @lg {
            padding: 8px 9px;
        }
    }

    //&__tbody-td-inner {
    //    .tbl__tbody-td--package-prod &,
    //    .tbl__tbody-td--price-prod & {
    //        flex-direction: column;
    //
    //        @media @md {
    //            flex-direction: row;
    //        }
    //    }
    //
    //    @media @md {
    //        display: flex;
    //        justify-content: center;
    //
    //        .tbl__tbody-td:first-child & {
    //            justify-content: flex-start;
    //        }
    //    }
    //}
    //&__tbody-td-inner--left {
    //    @media @md {
    //        justify-content: flex-start;
    //    }
    //}
    //&__tbody-td-inner--v-center {
    //    @media @md {
    //        align-items: center;
    //    }
    //}

    &--product {

        .tbl__thead-th {
            text-align: left;
        }

        .tbl__thead-th--per-pill-prod,
        .tbl__thead-th--savings-prod,
        .tbl__tbody-td--per-pill-prod,
        .tbl__tbody-td--savings-prod {
            display: none;

            @media @lg {
                display: table-cell;
            }
        }

        .tbl__tbody-td--savings-prod {
            font-weight: 700;
            color: #FC0D63;
        }

        .tbl__thead-th--order-prod {
            font-size: 0;

            @media @lg {
                font-size: 14px;
                text-align: right;
                padding-right: 44px;
            }
        }

        .tbl__tbody-td--package-prod {
            padding-left: 0;

            @media @lg {
                padding-left: 8px;
            }
        }

        .tbl__tbody-td--package-prod .tbl__tbody-td-inner {
            @media @lg {
                display: flex;
            }
        }

        .tbl__tbody-td--order-prod {
            padding-right: 0;

            @media @lg {
                padding-right: 16px;
            }
        }

        .tbl__tbody-td--package-prod,
        .tbl__tbody-td--price-prod {
            font-weight: 700;
        }

        .tbl__tbody-td--price-prod span {
            display: block;
            font-size: 14px;
            font-weight: 400;

            @media @lg {
                display: none;
            }
        }

        .tbl__tbody-td--order-prod {
            text-align: right;
        }

        .btn--prod {
            font-size: 20px;
            padding: 12px 13px;

            @media @lg {
                font-size: 16px;
                padding: 6px 11px;
            }
        }

        //table,
        //thead,
        //tbody,
        //tr,
        //th,
        //td {
        //    display: block;
        //}
        //
        //table {
        //    @media @md {
        //        display: table;
        //    }
        //}
        //
        //thead {
        //    @media @md {
        //        display: table-header-group;
        //    }
        //}
        //
        //tbody {
        //    @media @md {
        //        display: table-row-group;
        //    }
        //}
        //
        //tr {
        //    @media @md {
        //        display: table-row;
        //    }
        //}
        //
        //th,
        //td {
        //    @media @md {
        //        display: table-cell;
        //    }
        //}
        //
        //.tbl__thead-th--per-pill-prod,
        //.tbl__thead-th--savings-prod,
        //.tbl__tbody-td--per-pill-prod,
        //.tbl__tbody-td--savings-prod {
        //    display: none;
        //
        //    @media @md {
        //        display: table-cell;
        //    }
        //}
        //
        //.tbl__thead-th--order-prod {
        //    font-size: 0;
        //    width: 48px;
        //
        //    @media @md {
        //        font-size: 13px;
        //        width: auto;
        //    }
        //}
        //
        //.tbl__tbody-td--savings-prod,
        //.tbl__tbody-td--per-pill-prod {
        //    @media @md {
        //        font-weight: bold;
        //    }
        //}
        //
        //.tbl__tbody-td--savings-prod {
        //    color: @c_brand;
        //}
        //
        //.tbl__thead-tr {
        //    display: flex;
        //    justify-content: space-between;
        //
        //    @media @md {
        //        display: table-row;
        //    }
        //}
        //
        //.tbl__tbody {
        //    border-top: 1px solid #D2D4D9;
        //}
        //.tbl__tbody:last-child {
        //    border-bottom: 1px solid #D2D4D9;
        //}
        //
        //.tbl__tbody-tr {
        //    display: flex;
        //    justify-content: space-between;
        //    align-items: center;
        //
        //    @media @md {
        //        display: table-row;
        //    }
        //}
        //
        //.tbl__tbody-td {
        //    padding: 8px 0;
        //
        //    @media @md {
        //        padding: 8px 10px;
        //    }
        //}
        //
        //.tbl__tbody-td--price-prod {
        //    font-weight: bold;
        //}
        //
        //.tbl__tbody-td--price-prod span {
        //    display: block;
        //    font-weight: normal;
        //    font-size: 14px;
        //
        //    @media @md {
        //        display: none;
        //    }
        //}
        //
        //.tbl__tbody-td--order-prod button {
        //    padding: 11px;
        //
        //    @media @md {
        //        padding: 6px 11px;
        //    }
        //}
    }
}

.tbl--cart {
    margin-bottom: 24px;

    @media @lg {
        margin-bottom: 44px;
    }

    table,
    thead,
    tbody,
    tr,
    th,
    td {
        display: block;
    }

    table {
        @media @md {
            display: table;
        }
    }

    thead {
        @media @md {
            display: table-header-group;
        }
    }

    tbody {
        @media @md {
            display: table-row-group;
        }
    }

    tr {
        @media @md {
            display: table-row;
        }
    }

    th,
    td {
        @media @md {
            display: table-cell;
        }
    }

    .tbl__tbody {
        border-bottom: 1px solid #D2D4D9;
        padding-bottom: 3px;
    }

    .tbl__thead-th--package,
    .tbl__thead-th--qty,
    .tbl__thead-th--price,
    .tbl__thead-th--subtotal,
    .tbl__thead-th--remove {
        display: none;

        @media @md {
            display: table-cell;
        }
    }

    .tbl__thead-th {
        text-align: left;
    }

    .tbl__thead-th--qty {
        text-align: center;
    }

    .tbl__tbody-tr {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        @media @md {
            display: table-row;
        }
    }

    .tbl__tbody-td {
        border-bottom: none;
    }

    .tbl__tbody-td--product,
    .tbl__tbody-td--package,
    .tbl__tbody-td--subtotal {
        padding-left: 84px;
        width: 100%;

        @media @md {
            padding-left: 9px;
            width: auto;
        }
    }

    .tbl__tbody-td--product {
        font-weight: 700;
        padding-top: 12px;
        margin-bottom: -3px;

        @media @md {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        @media @lg {
            padding-top: 13px;
            padding-bottom: 13px;
        }
    }

    .tbl__tbody-td--product img {
        position: absolute;
        top: 14px;
        left: 10px;

        @media @md {
            position: static;
            margin-right: 4px;
        }

        @media @lg {
            margin-right: 15px;
        }
    }

    .tbl__tbody-td--product .tbl__tbody-td-inner {
        @media @md {
            display: flex;
            align-items: center;
        }
    }

    .tbl__tbody-td-inner {
        @media @md {
            display: flex;
            align-items: center;
        }
    }

    .tbl__tbody-td--package {
        padding-top: 0;
        margin-bottom: -3px;

        @media @md {
            padding-top: 8px;
            margin-bottom: 0;
        }

        @media @lg {
            font-weight: 700;
        }
    }

    .tbl__tbody-td--subtotal {
        font-weight: 700;
        padding-top: 0;
        margin-bottom: -3px;

        @media @md {
            padding-top: 8px;
            margin-bottom: 0;
        }
    }

    .tbl__tbody-td--remove {
        order: 1;
        margin-right: 4px;

        @media @md {
            margin-right: 0;
        }
    }

    .tbl__tbody-td--remove .tbl__tbody-td-inner {
        justify-content: center;
    }
    .tbl__tbody-td--remove i {
        font-size: 28px;
    }

    .tbl__tbody-td--remove span {
        font-weight: 700;
        color: #BAC0C9;

        &:hover {
            color: #8A8A8A;
        }
    }

    .tbl__tbody-td--remove button {
        border: none;
        color: #d2d4d9;
        padding: 5px 14px;
        background: none;
        cursor: pointer;

        &:hover {
            color: #8A8A8A;
        }
    }

    .tbl__tbody-td--qty {
        order: 2;
    }

    .tbl__tbody-td--qty .tbl__tbody-td-inner {
        @media @md {
            justify-content: center;
        }
    }

    .tbl__tbody-td--price {
        display: none;

        @media @md {
            display: table-cell;
        }
    }

    .tbl__tbody-td--upgrade {
        font-size: 12px;
        line-height: 16px;
        padding: 2px 0;

        @media @lg {
            padding: 9px 16px;
        }
    }

    .tbl__tbody-td--upgrade a {
        font-weight: 700;
        text-decoration: none;
    }

    .tbl__tbody-td--upgrade a:hover {
        text-decoration: underline;
    }
}


.tbl--method {
    .tbl__thead-th--price {
        font-size: 0;
    }

    .tbl__tbody-td {
        font-weight: bold;
        border-top: 1px solid #D2D4D9;
        height: 77px;
    }

    .tbl__tbody-td--method {
        padding-left: 9px;
    }

    .tbl__tbody-td--price {
        white-space: nowrap;
        width: 120px;
    }

    .tbl__tfoot-td {
        background-color: #191B1D;
    }
}


.shipping-footer {
    color: #F5F5F5;
    font-family: @ff_base;
    font-weight: bold;
    margin-bottom: 25px;

    @media @xl {
        margin-bottom: 69px;
    }

    &__inner {
        background-color: @c_bgDark;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column-reverse;

        @media @md {
            flex-direction: row;
        }

        @media @lg {
            margin-left: -8px;
            margin-right: -8px;
            padding-right: 49px;
        }
    }

    &__block-coupon {
        margin-bottom: 15px;

        @media @md {
            margin-bottom: 0;
        }

        @media @lg {
            display: flex;
            align-items: center;
        }
    }

    &__left {
        @media @lg {
            margin-right: 15px;
        }

        @media @xl {
            margin-right: 48px;
        }
    }

    &__block-code {
        position: relative;
        margin-bottom: 15px;

        @media @md {
            width: 325px;
        }

        @media @lg {
            margin-bottom: 0;
        }
    }

    &__submit {
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0;
        border-left-width: 1px;
        height: 100%;
        font-weight: 700;
        background-color: #fff;
        cursor: pointer;
        padding: 0 43px 0 30px;
        border-radius: 4px;
        border-color: #D2D4D9;
        font-family: @ff_base;

        &:hover {
            background-color: #f0f0f0;
        }

        @media @xl {
            padding: 0 28px;
        }
    }

    &__input-code {
        width: 100%;
        height: 48px;
        outline: none;
        border-radius: 4px;
        border: 1px solid #D2D4D9;
        padding: 0 140px 0 16px;
        letter-spacing: 0.5px;
        background-color: #fff;

        &::-webkit-input-placeholder {
            color: #BAC0C9;
        }

        &::-moz-placeholder {
            color: #BAC0C9;
        }

        /* Firefox 19+ */

        &:-moz-placeholder {
            color: #BAC0C9;
        }

        /* Firefox 18- */

        &:-ms-input-placeholder {
            color: #BAC0C9;
        }
    }

    &__right {
        text-align: center;
        font-family: @ff_second;
        font-weight: 700;
        font-size: 18px;
        font-variant-numeric: lining-nums;

        @media @lg {
            white-space: nowrap;
        }
    }

    &__desc {
        @media @md {
            margin-right: 15px;
        }
    }

    &__desc-text {
        display: flex;

        @media @xl {
            padding-top: 4px;
            padding-right: 20px;
        }
    }

    &__desc-text span {
        font-size: 14px;
        color: #7C828B;
        font-weight: 400;
        flex-grow: 1;
        line-height: 20px;
    }

    &__desc-text i {
        font-size: 28px;
        color: @c_brand;
        margin-right: 13px;

        @media @xl {
            margin-top: 6px;
        }
    }
}