@form__item-md-mr: 32px;

/* .refill-reminder */

.refill-reminder {
    margin-bottom: 40px;

    &__row {
        margin-bottom: 38px;

        @media @md {
            margin-bottom: 24px;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        margin-bottom: 29px;

        @media @xl {
            margin-bottom: 13px;
        }
    }
    &__control {
        position: relative;

        @media @lg {
            display: flex;
            align-items: center;
        }
    }
    &__radio-block {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @media @md {
            position: static;
            display: flex;
            margin-bottom: 15px;
        }

        @media @lg {
            margin-bottom: 0;
        }

        @media @xl {
            margin-right: 17px;
        }
    }
    &__select-block {
        padding-top: 32px;
        padding-left: 95px;

        @media @md {
            padding-top: 0;
            padding-left: 0;
            display: flex;
            align-items: center;
        }
    }
    &__select-days {
        margin-bottom: 8px;

        @media @md {
            margin-bottom: 0;
            margin-right: @form__item-md-mr;
        }
    }
    &__select-days .form__field--select {
        @media @xl {
            width: 146px;
        }
    }
    &__select-time {
        display: flex;
        justify-content: space-between;
    }
    &__select-time .form__item:first-child {
        margin-right: 45px;

        @media @md {
            margin-right: @form__item-md-mr;
        }
    }
    &__select-time .form__field--select {
        @media @xl {
            width: 93px;
        }
    }
    i {
        margin-right: 8px;
    }
    .i-phone {
        font-size: 24px;
    }
    .i-email {
        font-size: 18px;
    }
    .radio {
        margin-bottom: 24px;

        @media @md {
            margin-bottom: 0;
            margin-right: 33px;
        }
    }
    .radio:last-child {
        margin-right: 0;
    }
    .radio__label {
        padding-left: 32px;
        color: @c_text;
    }
    .form__item {
        position: relative;
        flex-grow: 1;

        @media @md {
            display: flex;
            align-items: center;
        }
    }
    .form__select-label {
        position: absolute;
        top: 15px;
        right: 100%;
        padding-right: 10px;
        max-width: 45px;

        @media @md {
            position: static;
        }

        @media @xl {
            max-width: none;
            padding-right: 0;
            margin-right: 16px;
        }
    }
}