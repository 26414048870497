/*.copy-to-buffer*/

@media @md {
    /*.copy-to-buffer @md*/
}

@media @xl {
    /*.copy-to-buffer @xl*/
}

.copy-to-buffer {
    background-color: @c_bgDark;
    color: #F5F5F5;
    text-align: center;
    border-radius: 8px;
    padding: 11px 11px 24px;
    margin-bottom: 25px;

    @media @md {
        height: 428px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media @xl {
        height: 340px;
        justify-content: flex-start;
        padding-top: 44px;
    }

    &__title {
        font-size: 15px;
        font-weight: 700;
        margin: 0 0 1px 0;
        text-transform: uppercase;

        @media @xl {
            margin-bottom: 4px;
        }
    }

    &__field {
        text-align: center;
        color: @c_brand;
        font-size: 42px;
        font-family: @ff_second;
        font-weight: 700;
        text-transform: uppercase;
        max-width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        margin-bottom: -3px;
        font-variant-numeric: lining-nums;

        @media @xl {
            margin-bottom: 24px;
        }
    }

    &__desc {
        color: @c_brand;
        font-size: 14px;
        margin-bottom: 4px;

        @media @xl {
            margin-bottom: 11px;
        }
    }

    .btn {
        max-width: 244px;
        padding: 14px 19px;

        @media @xl {
            width: auto;
        }
    }
}