/* .bestseller */

@media @lg { /* .bestseller @lg */ }

.bestseller {
    padding-top: 20px;
    margin-bottom: 35px;

    @media @lg {
        padding-top: 6px;
    }

    &__title {
        margin-top: 0;
        margin-bottom: 20px;

        @media @lg {
            margin-bottom: 18px;
        }
    }

    &__list {
        width: 100%;
        overflow-x: auto;
        display: flex;
    }

    .card {
        margin-right: 8px;
        padding: 25px 24px 22px;
        min-width: 230px;
        flex-grow: 1;

        &:last-child {
            margin-right: 0;
        }
    }
}