/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@icon-bonus-delivery-name: 'icon-bonus-delivery';
@icon-bonus-delivery-x: 0px;
@icon-bonus-delivery-y: 0px;
@icon-bonus-delivery-offset-x: 0px;
@icon-bonus-delivery-offset-y: 0px;
@icon-bonus-delivery-width: 53px;
@icon-bonus-delivery-height: 52px;
@icon-bonus-delivery-total-width: 140px;
@icon-bonus-delivery-total-height: 105px;
@icon-bonus-delivery-image: 'sprite.png';
@icon-bonus-delivery: 0px 0px 0px 0px 53px 52px 140px 105px 'sprite.png' 'icon-bonus-delivery';
@icon-bonus-medicine-name: 'icon-bonus-medicine';
@icon-bonus-medicine-x: 54px;
@icon-bonus-medicine-y: 53px;
@icon-bonus-medicine-offset-x: -54px;
@icon-bonus-medicine-offset-y: -53px;
@icon-bonus-medicine-width: 52px;
@icon-bonus-medicine-height: 52px;
@icon-bonus-medicine-total-width: 140px;
@icon-bonus-medicine-total-height: 105px;
@icon-bonus-medicine-image: 'sprite.png';
@icon-bonus-medicine: 54px 53px -54px -53px 52px 52px 140px 105px 'sprite.png' 'icon-bonus-medicine';
@icon-bonus-security-name: 'icon-bonus-security';
@icon-bonus-security-x: 54px;
@icon-bonus-security-y: 0px;
@icon-bonus-security-offset-x: -54px;
@icon-bonus-security-offset-y: 0px;
@icon-bonus-security-width: 53px;
@icon-bonus-security-height: 52px;
@icon-bonus-security-total-width: 140px;
@icon-bonus-security-total-height: 105px;
@icon-bonus-security-image: 'sprite.png';
@icon-bonus-security: 54px 0px -54px 0px 53px 52px 140px 105px 'sprite.png' 'icon-bonus-security';
@icon-bonus-sertify-name: 'icon-bonus-sertify';
@icon-bonus-sertify-x: 0px;
@icon-bonus-sertify-y: 53px;
@icon-bonus-sertify-offset-x: 0px;
@icon-bonus-sertify-offset-y: -53px;
@icon-bonus-sertify-width: 53px;
@icon-bonus-sertify-height: 52px;
@icon-bonus-sertify-total-width: 140px;
@icon-bonus-sertify-total-height: 105px;
@icon-bonus-sertify-image: 'sprite.png';
@icon-bonus-sertify: 0px 53px 0px -53px 53px 52px 140px 105px 'sprite.png' 'icon-bonus-sertify';
@icon-quote-name: 'icon-quote';
@icon-quote-x: 108px;
@icon-quote-y: 0px;
@icon-quote-offset-x: -108px;
@icon-quote-offset-y: 0px;
@icon-quote-width: 32px;
@icon-quote-height: 28px;
@icon-quote-total-width: 140px;
@icon-quote-total-height: 105px;
@icon-quote-image: 'sprite.png';
@icon-quote: 108px 0px -108px 0px 32px 28px 140px 105px 'sprite.png' 'icon-quote';
@spritesheet-width: 140px;
@spritesheet-height: 105px;
@spritesheet-image: 'sprite.png';
@spritesheet-sprites: @icon-bonus-delivery @icon-bonus-medicine @icon-bonus-security @icon-bonus-sertify @icon-quote;
@spritesheet: 140px 105px 'sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  background-image: e(%('url(%a)', e(@sprite-image)));
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
