/*.header*/

@media @lg {
  /*.header @lg*/
}

.header {
  position: fixed;
  background-color: @c_bgDark;
  top: 80px;
  left: -100%;
  bottom: 0;
  width: 100%;
  transition: left ease-in 300ms;
  overflow-y: auto;
  z-index: 10;

  @media @lg {
    position: static;
    background-color: @c_bgBody;
    margin-bottom: 24px;
  }

  &.opened {
    left: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;

    max-width: 360px;
    //background-color: #f00;

    @media @lg {
      max-width: @container;
      display: block;
    }
  }
  &__top-row {
    order: 2;

    @media @lg {
      padding-top: 8px;
      margin-bottom: 5px;
    }
  }
  &__top-bottom {
    order: 1;
  }
  &__mob-body {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: @c_bgBody;

    @media @lg {
      position: static;
    }
  }
  &__block-menu {
    @media @lg {
      display: flex;
      //flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__wrap-mob-body {
    flex-grow: 0;
    padding: 0;
  }
  &__live-chat {
    @media @lg {
      display: inline-block;
      width: 151px;
      min-width: 151px;
      height: 143px;
      background: url("../img/chat.png") no-repeat 50% 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      color: #FAFAFA;
      font-size: 20px;
      font-family: @ff_second;
      font-weight: 700;
      position: relative;
      top: 5px;
      right: -8px;
      padding-bottom: 17px;
    }
  }
  &__talking-block {
    @media @lg {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
  &__live-chat span {
    @media @lg {
      color: #FFF62B;
      font-size: 28px;
    }
  }
  &__navigation {
    @media @lg {
      margin-right: 15px;
    }
  }
  &__social-notes {
    @media @lg {
      margin-right: 3px;
      padding-top: 6px;
    }
  }
  &__mob-body-inner {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    margin: 0 auto;

    @media @lg {
      max-width: @container;
    }
  }
  &__logo {
    margin-right: 14px;

    @media @lg {
      padding-top: 60px;
    }
  }
  &__logo img {
    height: 58px;

    @media @lg {
      max-height: 92px;
      min-height: 92px;
    }
  }
  &__toggle-menu {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 19px;
    margin-left: 4px;
  }
  &__toggle-menu.opened {
    padding: 0 10px;
  }
  &__toggle-menu.opened i:before {
    content: '\e901';
  }
  &__link-to-cart {
    text-decoration: none;
    color: @c_brand;
    font-size: 27px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  &__open-search {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 28px;
    margin-right: 13px;
  }
  &__search {
    position: fixed;
    top: 0;
    left: 100%;
    background-color: @c_bgBody;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    transition: left ease-in 300ms;
    z-index: 1;

    @media @lg {
      position: static;
      padding: 10px 0 0 16px;
      display: block;
      background-color: transparent;
      width: 59.5%;
    }
  }
  &__search.opened {
    left: 0;
  }
  &__bottom-row {
    @media @lg {
      background-color: @c_bgDark;
      margin-left: -8px;
      margin-right: -8px;
      border-radius: 12px;
    }
  }
  &__bottom-row .col {
    @media @lg {
      display: flex;
      justify-content: space-between;
      padding-top: 17px;
      padding-bottom: 15px;
    }
  }
  &__search-field {
    width: 100%;

    max-width: 360px;

    @media @lg {
      max-width: none;
    }
  }
  &__mob-search {
    display: flex;
  }
  &__select-block {
    display: flex;
    justify-content: space-between;
    padding: 7px 12px 6px;
    background: @c_bgDark;
    margin-left: -8px;
    margin-right: -8px;

    @media @lg {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1px;
    }
  }
  &__select-block > * {
    flex-grow: 1;
    margin-right: 0;
  }
  &__select-block > *:last-child {
    margin-right: 0;
  }
  &__cart {
    background-color: #fff;
    border-bottom: 1px solid #D2D4D9;
    margin-left: -8px;
    margin-right: -8px;
    padding: 0 8px;

    @media @lg {
      background-color: transparent;
      margin-left: 0;
      margin-right: 0;
      border-bottom: none;
    }
  }
}