/* .product-bonuses */

@media @lg {
    /* .product-bonuses @lg */
}

.product-bonuses {
    position: relative;
    top: 2px;

    @media @lg {
        padding-left: 8px;
    }

    i {
        font-size: 20px;

        @media @lg {
            font-size: 24px;
        }
    }

    //.form-buy-product & {
    //    padding-left: 4px;
    //}

    &__item {
        position: relative;
        margin-right: 4px;

        @media @lg {
            margin-right: 8px;
        }

        //.form-buy-product & {
        //    padding-right: 3px;
        //}
        //
        //.form-buy-product &:last-child {
        //    margin-right: 0;
        //}
    }

    &__item:last-child {
        margin-right: 0;
    }

    &__tooltip {
        position: absolute;
        bottom: 36px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        color: #F5F5F5;
        white-space: nowrap;
        background-color: #191B1D;
        padding: 8px 5px;
        display: none;
        border-radius: 8px;

        .product-bonuses__item.icon-prop-free-ship-mob & {
            margin-left: -37px;
        }

        .product-bonuses__item.icon-prod-bonus-mob & {
            margin-left: -19px;
        }

        .product-bonuses__item.icon-prod-hot-mob & {
            margin-left: -13px;
            background-color: @c_brand;
        }

        .product-bonuses__item.icon-prod-hot-mob &:before {
            border-color: @c_brand transparent transparent transparent;
        }

        .product-bonuses__item:hover & {
            @media @lg {
                display: block;
            }
        }
    }

    &__tooltip:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -7px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 0 7px;
        border-color: #191b1d transparent transparent transparent;
    }
}